<template>
  <v-dialog v-model="isModalOpen" width="550" persistent>
    <v-form ref="form" v-model="is_valid">
      <v-card v-if="currentTransferItem">
        <v-toolbar class="py-2" flat>
          <v-toolbar-title>
            <h2 class="font-weight-regular text-h3">{{ $t("add_item") }}</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-0">
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <div class="d-flex flex-row mx-4">
            <v-img
              class="me-4"
              max-height="200"
              max-width="200"
              v-if="currentTransferItem?.image"
              :src="currentTransferItem?.image?.url"
            />
            <v-img
              v-else
              class="me-4"
              src="@/assets/product.png"
              max-height="100"
              max-width="100"
            />
            <div class="">
              <label class="ma-1 font-weight-regular">{{
                currentTransferItem?.name
              }}</label>
              <br />
              <label class="text--disabled ma-1 body-1">
                ID: {{ currentTransferItem.code }} . Barcode :
                {{ currentTransferItem.barcode }}</label
              >
              <br />
              <p class="mt-2 ml-1 font-weight-medium red--text text--lighten-1">
                {{ currentTransferItem?.qte_in_hub ?? 0 }} items in stock
              </p>
            </div>
          </div>
          <div class="mx-4">
            <div
              class="
                text-h5
                font-weight-medium
                d-flex
                justify-space-between
                py-4
              "
              style="min-width: 200px"
            >
              <span>{{ $t("units_per_package") }}</span>
              <span>{{
                currentTransferItem?.product_info?.package_units
              }}</span>
            </div>
            <v-divider></v-divider>
            <div
              class="
                text-h5
                font-weight-medium
                d-flex
                justify-space-between
                py-4
              "
              style="min-width: 200px"
            >
              <span>{{ $t("units_per_palette") }}</span>
              <span>{{
                currentTransferItem?.product_info?.palette_units
              }}</span>
            </div>
            <v-divider></v-divider>
          </div>
          <v-row class="mt-9 mx-2">
            <v-col class="d-flex" cols="12" sm="6" md="6" style="gap: 10px">
              <v-text-field
                dense
                hide-details="auto"
                outlined
                :label="$t('quantity') + '(' + $t('items') + ')'"
                v-model="currentTransferItem.qte_in_transfer"
                @keypress="isNumber($event)"
                :rules="[
                  rules.min_quantity(1),
                  rules.max_quantity(currentTransferItem.qte_in_hub),
                ]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-6"
            color="#6100ea"
            @click="saveForm()"
            :loading="isLoading"
            :disabled="isLoading"
          >
            <span> {{ $t("add_items_to_cart") }} </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import service from "@/store/services/transfer-product-service";
import { Product } from "../../setup/products/product.class";

export default {
  mixins: [validationRules],
  props: {
    isModalOpen: Boolean,
    transferItem: Object,
    toggleModal: Function,
  },
  mounted: function () {
    this.currentTransferItem = { ...this.transferItem };
    this.prepareProduct();
  },
  computed: {
    isVisibleDialog: {
      get() {
        return this.$store.state.products.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("products/IS_VISIBLE_DIALOG", value);
      },
    },
    transfer: {
      get() {
        return this.$store.state.transferProducts.transfer;
      },
      set(value) {
        this.$store.commit("transferProducts/TRANSFER", value);
      },
    },
  },
  data() {
    return {
      product: new Product(),
      editUnit: null,
      message: null,
      currentTransferItem: null,
      qte_by_type_packaging: 0,
      is_valid: false,
      discount: 0,
      isLoading: false,
    };
  },
  watch: {},

  methods: {
    close() {
      this.toggleModal();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        // charCode > 31 &&
        charCode < 48 ||
        charCode > 57
        // charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    prepareProduct() {
      const orderItem = {
        qte_in_transfer: this.currentTransferItem.qte_in_transfer,
        code: this.currentTransferItem.code,
        id: this.currentTransferItem.id,
        transfer_id: this.transfer.id,
      };

      Object.assign(this.currentTransferItem, orderItem);
    },

    validate() {
      this.$refs.form.validate();
    },

    async saveForm() {
      this.isLoading = true;
      this.validate();
      if (this.is_valid) {
        try {
          if (!this?.transfer?.id) {
            const createdTransfer = await service.add({
              ...this.transfer,
            });
            this.transfer.id = createdTransfer.id;
          }
          this.currentTransferItem.transfer_id = this.transfer.id;

          const transferItem = {
            id: this.currentTransferItem.id,
            qte_in_transfer: this.currentTransferItem.qte_in_transfer,
            transfer_id: this.transfer.id,
          };

          this.$store.dispatch("transferProducts/addToCart", transferItem);
          this.toggleModal();
          this.$store.dispatch(
            "alerts/success",
            this.$t("item_added_successfully")
          );
        } catch (error) {
          this.isLoading = false;
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
  },
};
</script>
